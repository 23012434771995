  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'modal.modal-title'}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!--... Add user modal-->
    <form>
      <div class="form-group">
        <label for="exampleInputEmail1">Email</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
               placeholder="Email">
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Mot de passe</label>
        <input type="password" class="form-control" id="exampleInputPassword1"
               placeholder="Mot de passe">
      </div>
      <div class="form-group">
        <label for="nameInput">Nom</label>
        <input type="text" class="form-control" id="nameInput"
               placeholder="Nom">
      </div>
      <div class="form-group">
        <label for="nameInput">Prénom(s)</label>
        <input type="text" class="form-control" id="surNameInput"
               placeholder="Prénom(s)">
      </div>
      <div class="custom-control custom-checkbox mb-3">
        <input type="checkbox" class="custom-control-input" id="customCheck1">
        <label class="custom-control-label" for="customCheck1">{{ 'forms.custom-checkbox' | translate }}</label>
      </div>
      <button type="submit" class="btn btn-primary mb-0">{{ 'forms.submit' | translate }}</button>
    </form>
  </div>
<!--</ng-template>-->
