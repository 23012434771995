import { Injectable } from '@angular/core';
import {Action} from '../../../../constants/action.models';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';

export class Entrepot extends Action{
  contact = '';
  entrepotId = '';
  libelle = '';
  situationGeographique = '';
  produitId: string;
}
@Injectable({
  providedIn: 'root'
})
export class DepotService {
  apiFile = 'controllers/coDepot.php';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  constructor(private http: HttpClient) {
    this.headers.append('Access-Control-Allow-Origin', '*');
  }
  getAllDepotItems(): Observable<Entrepot[]> {
    const url = `${environment.devApiUrl + this.apiFile}?Action=SelectAll`;
    return this.http.get<Entrepot[]>(url, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllStocksDepot( entrepot : Entrepot): Observable<Entrepot[]> {
    const url = `${environment.devApiUrl + this.apiFile}?Action=SelectAllBy&entrepotId=${entrepot.entrepotId}`;
    return this.http.get<Entrepot[]>(url, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }

  postDepotItems = (entrepot: Entrepot): Observable<Entrepot[]> => {
    const url = `${environment.devApiUrl + this.apiFile}?Action=${entrepot.actionType === 'add' ? environment.actionType.INSERT_ACTION : environment.actionType.UPDATEBY_ACTION}`;
    const FORMDATA: any = new FormData();
    FORMDATA.append('entrepotId', entrepot.entrepotId);
    FORMDATA.append('libelle', entrepot.libelle);
    FORMDATA.append('situationGeographique', entrepot.situationGeographique);
    FORMDATA.append('contact', entrepot.contact);
    FORMDATA.append('createdBy', entrepot.createdBy);
    return this.http.post<any>(url, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Delete
  deleteDepottems = (entrepotId: string): Observable<any> => {
    const url = `${environment.devApiUrl + this.apiFile}?Action=${environment.actionType.DELETEBY_ACTION }&entrepotId=${entrepotId}`;
    return this.http.delete(url, { headers: this.headers }).pipe(retry(3), catchError(this.handleError));
  }
}
