import { Injectable } from '@angular/core';
import {Action} from '../../../../constants/action.models';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';
import {TypeUser} from './type-utilisateur.service';


export class User extends Action{
  userId = '';
  Actif : string;
  nomUser = '';
  prenomUser = '';
  sexeUser = '';
  telUser = '';
  photoUser = '';
  adresseUser = '';
  entrepotId = '';
  typeUserId = '';
  code : number ;
}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiFile = 'controllers/coUser.php';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  constructor(private http: HttpClient) {
    this.headers.append('Access-Control-Allow-Origin', '*');
  }

  getAllUserItems(): Observable<User[]> {
    const url = `${environment.devApiUrl + this.apiFile}?Action=SelectAll`;
    return this.http.get<User[]>(url, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }

  postUserItems = (user: User): Observable<User[]> => {
    const url = `${environment.devApiUrl + this.apiFile}?Action=${user.actionType === 'add' ? environment.actionType.INSERT_ACTION : environment.actionType.UPDATEBY_ACTION}`;
    const FORMDATA: any = new FormData();
    FORMDATA.append('userId', user.userId);
    FORMDATA.append('nomUser', user.nomUser);
    FORMDATA.append('prenomUser', user.prenomUser);
    FORMDATA.append('sexeUser', user.sexeUser);
    FORMDATA.append('telUser', user.telUser);
    FORMDATA.append('adresseUser', user.adresseUser);
    FORMDATA.append('photoUser', user.photoUser);
    FORMDATA.append('typeUserId', user.typeUserId);
    FORMDATA.append('entrepotId', user.entrepotId);
    FORMDATA.append('createdBy',  user.createdBy);
    return this.http.post<any>(url, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
 deleteItems = (UserId: string, createdBy: string): Observable<User[]> => {
    const url = `${environment.devApiUrl + this.apiFile}?Action=${environment.actionType.DELETEBY_ACTION}`;
    const FORMDATA: any = new FormData();
    FORMDATA.append('userId', UserId);
    FORMDATA.append('createdBy', createdBy);
    return this.http.post<any>(url, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }

  BloquerAccess = (UserId: string,  Actif : string, createdBy: string): Observable<User[]> => {
    const url = `${environment.devApiUrl + this.apiFile}?Action=${environment.actionType.DELETE_ACTION}`;
    const FORMDATA: any = new FormData();
    FORMDATA.append('userId', UserId);
    FORMDATA.append('Actif', Actif);
    FORMDATA.append('createdBy', createdBy);
    return this.http.post<any>(url, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }
}
