import { Injectable } from '@angular/core';
import {Action} from '../../../../constants/action.models';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {catchError, retry} from 'rxjs/operators';

export class TypeCasier extends Action{
  typeCasierId = '';
  libelle = '';
  description = '';
  quantite = 0;
}

@Injectable({
  providedIn: 'root'
})
export class TypecasierService {
  apiFile = 'controllers/coTypeCasier.php';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  handleError = (error: HttpErrorResponse) => {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  constructor(private http: HttpClient) {
    this.headers.append('Access-Control-Allow-Origin', '*');
  }

  getAllTypeCasier(): Observable<TypeCasier[]> {
    const url = `${environment.devApiUrl + this.apiFile}?Action=SelectAll`;
    return this.http.get<TypeCasier[]>(url, {headers: this.headers})
      .pipe(retry(1), catchError(this.handleError));
  }

  AddOrUpdateTypeCasier = (typecasier: TypeCasier): Observable<TypeCasier[]> => {
    const url = `${environment.devApiUrl + this.apiFile}?Action=${typecasier.actionType === 'add' ? environment.actionType.INSERT_ACTION : environment.actionType.UPDATEBY_ACTION}`;
    const FORMDATA: any = new FormData();
    FORMDATA.append('typeCasierId', typecasier.typeCasierId);
    FORMDATA.append('libelle', typecasier.libelle);
    FORMDATA.append('description', typecasier.description);
    FORMDATA.append('quantite', typecasier.quantite);
    FORMDATA.append('createdBy', typecasier.createdBy);
    return this.http.post<any>(url, FORMDATA)
      .pipe(retry(1), catchError(this.handleError));
  }

  deleteTypeCasier = (typecasier: TypeCasier): Observable<any> => {
    const url = `${environment.devApiUrl + this.apiFile}?Action=${environment.actionType.DELETEBY_ACTION}&typeCasierId=${typecasier.typeCasierId}&createdBy=${typecasier.createdBy}`;
    return this.http.delete(url, {headers: this.headers}).pipe(retry(3), catchError(this.handleError));
  }

}
